import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment'
import InPlayListItem from "./components/in-play-list-item/in-play-list-item";
import LoadingOverlay from "./components/loading-overlay/loading-overlay";
require('moment-timezone');

export default function Inplay(props) {
  const [cricket, setcricket] = useState([]);
  const [Soccer, setSoccer] = useState([]);
  const [Tennis, setTennis] = useState([]);
  const [Basketball, setBasketball] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);
  const [ScoreData, setScoreData] = useState([])

  var MKPIN = "MKPIN", MKPINSC = "MKPINSC", MKPINTN = "MKPINTN";



  useEffect(() => {

    axios
      .get(`https://liveapi247.live/api8/basketballData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setBasketball(obj);
      })
      .catch((error) => console.log(error));
    axios
      .get(`https://liveapi247.live/api8/tennisData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setTennis(obj);
      })
      .catch((error) => console.log(error));

    axios
      .get(`https://liveapi247.live/api8/soccerData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setSoccer(obj);
      })

      .catch((error) => console.log(error));

    axios
      .get(`https://liveapi247.live/api8/cricketData`)

      .then((result) => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }

        });

        setcricket(obj);
      })

      .catch((error) => console.log(error));

    axios.get(
      `https://liveapi247.live/api8/checktv`)

      .then(result => {

        settv(result.data);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api1/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));

    axios
      .get(`https://liveapi247.live/api8/fancy`)

      .then((result) => {

        setFancy(result.data);
        setrefresh(false);
      })

      .catch((error) => console.log(error));


    axios.get(
      `https://liveapi247.live/api8/getskyScoreId`)

      .then(result => {

        setScoreData(result.data.data.ScoreID);
      })
      .catch(error => console.log(error));


  }, []);


    useEffect(() => {
        var list = JSON.parse(localStorage.getItem("multi") || "[]");

        if (props.isLoggedIn == true) {

            for (var i = 0; i < cricket.length; i++) {

                if (!document.getElementById(MKPIN + i)) {
                    setcricketUpdate(!cricketUpdate);
                    return;
                }
                if (list.includes(cricket[i].eventId)) {
                    var mkpin = document.getElementById(MKPIN + i);
                    mkpin.className = "pin-on"
                }
                else {
                    var mkpin = document.getElementById(MKPIN + i);
                    mkpin.className = "pin-off";
                }
            }
        }
        else {
            for (var i = 0; i < cricket.length; i++) {

                if (!document.getElementById(MKPIN + i)) {
                    setcricketUpdate(!cricketUpdate);
                    return;
                }
                if (list.includes(cricket[i].eventId)) {
                    var mkpin = document.getElementById(MKPIN + i);
                    mkpin.className = "pin-off"
                }
                else {
                    var mkpin = document.getElementById(MKPIN + i);
                    mkpin.className = "pin-off";
                }
            }
        }

    }, [cricket, cricketUpdate]);

    useEffect(() => {
        var list = JSON.parse(localStorage.getItem("multi") || "[]");

        if (props.isLoggedIn == true) {

            for (var i = 0; i < Soccer.length; i++) {

                if (!document.getElementById(MKPINSC + i)) {
                    setcricketUpdate(!cricketUpdate);
                    return;
                }
                if (list.includes(Soccer[i].eventId)) {
                    var mkpin = document.getElementById(MKPINSC + i);
                    mkpin.className = "pin-on"
                }
                else {
                    var mkpin = document.getElementById(MKPINSC + i);
                    mkpin.className = "pin-off";
                }
            }
        }
        else {
            for (var i = 0; i < Soccer.length; i++) {

                if (!document.getElementById(MKPINSC + i)) {
                    setcricketUpdate(!cricketUpdate);
                    return;
                }
                if (list.includes(Soccer[i].eventId)) {
                    var mkpin = document.getElementById(MKPINSC + i);
                    mkpin.className = "pin-off"
                }
                else {
                    var mkpin = document.getElementById(MKPINSC + i);
                    mkpin.className = "pin-off";
                }
            }
        }

    }, [Soccer, cricketUpdate]);

    useEffect(() => {
        var list = JSON.parse(localStorage.getItem("multi") || "[]");

        if (props.isLoggedIn == true) {

            for (var i = 0; i < Tennis.length; i++) {

                if (!document.getElementById(MKPINTN + i)) {
                    setcricketUpdate(!cricketUpdate);
                    return;
                }
                if (list.includes(Tennis[i].eventId)) {
                    var mkpin = document.getElementById(MKPINTN + i);
                    mkpin.className = "pin-on"
                }
                else {
                    var mkpin = document.getElementById(MKPINTN + i);
                    mkpin.className = "pin-off";
                }
            }
        }
        else {
            for (var i = 0; i < Tennis.length; i++) {

                if (!document.getElementById(MKPINTN + i)) {
                    setcricketUpdate(!cricketUpdate);
                    return;
                }
                if (list.includes(Tennis[i].eventId)) {
                    var mkpin = document.getElementById(MKPINTN + i);
                    mkpin.className = "pin-off"
                }
                else {
                    var mkpin = document.getElementById(MKPINTN + i);
                    mkpin.className = "pin-off";
                }
            }
        }

    }, [Tennis, cricketUpdate]);





    const Multimarketpin = (index, event_id) => {
        if (props.isLoggedIn === false) {
            props.checkShowLogin(true)
        }
        else {
            var list = JSON.parse(localStorage.getItem("multi") || "[]");
            let ctr = 0;
            for (let i = 0; i < list.length; i++) {
                if (list[i] == event_id) {
                    let j = i + 1;
                    ctr = 1;
                    for (; i < list.length; j++, i++) {
                        list[i] = list[j];
                    }
                    list.splice([i - 1]);
                }
            }
            if (ctr == 0) {
                list.push(event_id);
            }
            localStorage.setItem("multi", JSON.stringify(list));
            setcricketUpdate(!cricketUpdate);

            // console.log('2', list);

        }
    }

 


  return (
    <React.Fragment>

      <LoadingOverlay
        show={refresh === true}
      />

      {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0 || Basketball.length > 0) && refresh === false && <div className="mob-nav-highlight_list" id="inPlayData">

        {cricket.length > 0 &&
        <>
<div id="inPlayEventType_4" style={{ display: "block" }}>
<div class="game_icons">
							<img alt="" src="https://d1gvwx1uptx1i3.cloudfront.net/sporticon/icon/4-icon.png"/>
							<h3>Cricket</h3>
						</div>
          <div class="game_ban"><img alt="" src="https://s3.ap-south-1.amazonaws.com/tokyo.assets/sporticon/4.png"/></div>

          <ul className="mob-sports-highlight-list" id="inPlayList_4">
            {cricket.map(function (item, id) {

              let customScore = '';
              let elapsTime = '';
              let filteredData = ScoreData &&
                ScoreData.filter(x => String(x.eventId) === String(item.eventId));
              // let filteredData = [];
              if (filteredData && filteredData.length > 0) {
                customScore = filteredData[0].score;
                elapsTime = filteredData[0].timeElapsed
              }

              let isFancy = false, showtv = false, isbookmaker = false;

              if (fancy.includes(item.eventId)) {
                isFancy = true;
              }

              if (tv.includes(item.eventId)) {
                showtv = true;
              }
              if (bookmaker.includes(item.eventId)) {
                isbookmaker = true;

              }

              return (
                <React.Fragment key={id}>
                  {moment() >= moment(item.marketStartTime) && (
                    <InPlayListItem
                      isInplay
                      sportsId={4}
                      eventId={item.eventId}
                      marketId={item.marketId}
                      linkOnClick={() => props.changeState(true)}
                      isTv={showtv}
                      isFancy={isFancy}
                      isBookmaker={isbookmaker}
                      score={customScore}
                      runnerName1={item.runnerName1}
                      runnerName2={item.runnerName2}
                      pinId={MKPIN + id}
                      pinOnClick={() => Multimarketpin(id, item.eventId)}
                    />
                  )}
                </React.Fragment>
              );
            })}

          </ul>
        </div></>}

        {Soccer.length > 0 && 
        <>
        <div id="inPlayEventType_1" style={{ display: "block" }}>
        <div class="game_icons">
								<img alt="" src="https://d1gvwx1uptx1i3.cloudfront.net/sporticon/icon/1-icon.png"/>
								<h3>Soccer</h3>
							</div>
          
          <div class="game_ban"><img alt="" src="https://s3.ap-south-1.amazonaws.com/tokyo.assets/sporticon/1.png"/></div>
          <ul className="mob-sports-highlight-list" id="inPlayList_1">
            {Soccer.map(function (item, id) {
              let customScore = '';
              let elapsTime = '';
              let filteredData = ScoreData &&
                ScoreData.filter(x => String(x.eventId) === String(item.eventId));
              // let filteredData = [];
              if (filteredData && filteredData.length > 0) {
                customScore = filteredData[0].score;
                elapsTime = filteredData[0].timeElapsed
              }

              let showtv = false, isbookmaker = false;

              if (tv.includes(item.eventId)) {
                showtv = true;
              }
              if (bookmaker.includes(item.eventId)) {
                isbookmaker = true;
              }

              return (
                <React.Fragment key={id}>
                  {moment() >= moment(item.marketStartTime) && (
                    <InPlayListItem
                      isInplay={moment() >= moment(item.marketStartTime)}
                      isPremium
                      sportsId={1}
                      eventId={item.eventId}
                      marketId={item.marketId}
                      linkOnClick={() => props.changeState(true)}
                      isTv={showtv}
                      isBookmaker={isbookmaker}
                      score={customScore}
                      runnerName1={item.runnerName1}
                      runnerName2={item.runnerName2}
                      pinId={MKPINSC + id}
                      pinOnClick={() => Multimarketpin(id, item.eventId)}
                    
                    />
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </div></>}

        {Tennis.length > 0 && <div id="inPlayEventType_2" style={{ display: "block" }}>
        <div class="game_icons">
								<img alt="" src="https://d1gvwx1uptx1i3.cloudfront.net/sporticon/icon/2-icon.png"/>
								<h3>Tennis</h3>
							</div><div class="game_ban"><img alt="" src="https://s3.ap-south-1.amazonaws.com/tokyo.assets/sporticon/2.png"/></div>
          <ul className="mob-sports-highlight-list" id="inPlayList_2">

            {Tennis.map(function (item, id) {
              let customScore = '';
              let elapsTime = '';
              let filteredData = ScoreData &&
                ScoreData.filter(x => String(x.eventId) === String(item.eventId));
              // let filteredData = [];
              if (filteredData && filteredData.length > 0) {
                customScore = filteredData[0].score;
                elapsTime = filteredData[0].timeElapsed
              }

              let showtv = false, isbookmaker = false;

              if (tv.includes(item.eventId)) {
                showtv = true;
              }
              if (bookmaker.includes(item.eventId)) {
                isbookmaker = true;
              }

              return (
                <React.Fragment key={id}>
                  {moment() >= moment(item.marketStartTime) && (
                    <InPlayListItem
                      isInplay={moment() >= moment(item.marketStartTime)}
                      isPremium
                      sportsId={2}
                      eventId={item.eventId}
                      marketId={item.marketId}
                      linkOnClick={() => props.changeState(true)}
                      isTv={showtv}
                      isBookmaker={isbookmaker}
                      score={customScore}
                      runnerName1={item.runnerName1}
                      runnerName2={item.runnerName2}
                      pinId={MKPINTN + id}
                      pinOnClick={() => Multimarketpin(id, item.eventId)}
                   
                    />
                  )}
                </React.Fragment>
              );
            })}

          </ul>
        </div>}

        {/* basketball */}

        {/* {Basketball.length > 0 && <div  id="inPlayEventType_2" style={{ display: "block" }}>
          <h3  >Basketball</h3>
          <ul  className="mob-sports-highlight-list" id="inPlayList_2">




            {Basketball.map(function (item, id) {

        let customScore = '';
        let elapsTime = '';
        let filteredData = ScoreData &&
 ScoreData.filter(x => String(x.eventId) === String(item.eventId));
        // let filteredData = [];
        if (filteredData && filteredData.length > 0) {
          customScore = filteredData[0].score;
          elapsTime = filteredData[0].timeElapsed
        }


              var showColor = false;

              var showtv = false;
              var isbookmaker = false;

              if (tv.includes(item.eventId)) {
                showtv = true;
              }
              if (bookmaker.includes(item.eventId)) {
                isbookmaker = true;

              }
              return (
                <React.Fragment key={id}>
                  {moment() >= moment(item.marketStartTime) && (
                    <li
                      id="inPlayEvent_-10065496"
                      key={id}
                      className={`inplay-on`}
                    >
                      <Link  to={`/exchange/displaymatch/fullmarket/7522/${item.eventId}/${item.marketId}`}
                        id="info"
                        onClick={() => {
                          props.changeState(true);
                        }}
                      >
                        <dl  >
                          <dt  >
                            {showtv && <span id="streamingIcon" className="game-live">
                              game-live
                            </span>}

                            {isbookmaker &&
                              <span  id="bookMakerIcon" className={`game-bookmaker in-play`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}

<span id="sportsBookIcon_2" className="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>
                            {moment() >= moment(item.marketStartTime) && (
                              <span  id="inPlayStatus" className="time">
                                In-Play
                              </span>
                            )}


                          </dt>
                          <dd   id="eventName">{item.runnerName1} <span className="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
                        </dl>
                      </Link>
                      <a  id={MKPINTN + id} className="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
                    </li>
                  )}
                </React.Fragment>
              );
            })}

          </ul>
        </div>} */}

      </div>}

      {(cricket.length === 0 && Soccer.length === 0 && Tennis.length === 0 && Basketball.length === 0) && refresh === false &&
        <div id="noMultiMarkets" className="no-data">
          <h3>There are currently no matches in Play.</h3>
          <p>Please check upcoming matches.</p>
        </div>
      }
    </React.Fragment>
  );
}
