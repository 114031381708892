import React, { useEffect, useState } from 'react'
import Loading from "../images/sprite/loading40.gif"
import axios from "axios";


export default function ActivityLog(props) {

   const [LogHistory, setLogHistory] = useState([]);


   useEffect(async () => {
      if (!props.userid) return;

      // const getLogHistory = ()

      axios
         .post("https://liveapi247.live/api1/activityLogs", {
            id: props.userid,
         })
         .then((result) => {
            if (result.status === 210) {
               setLogHistory(result.data);
            }
         })
         .catch((e) => {
            //setIsError(true);
         });
   }, []);

   return (
      <React.Fragment>
         <div _ngcontent-lst-c67="" class="col-left">

            <div _ngcontent-lst-c67="" class="sub_path">

               <div _ngcontent-lst-c67="" class="path">

                  <ul _ngcontent-lst-c67="" >
                     <li _ngcontent-lst-c67="" class="path-last"><a >My Account</a></li>
                  </ul>
               </div>

               <ul _ngcontent-lst-c67="" id="sports-path-popup" class="sports-path-popup">
               </ul>

               <ul _ngcontent-lst-c67="" class="sports-side-menu-list">
                  <li _ngcontent-lst-c67="" ><a id="sub_menu_detail" href="/exchange/displaymatch/myprofile">My Profile</a></li>
                  <li _ngcontent-lst-c67="" ><a id="sub_menu_summary" href="/exchange/displaymatch/balance" >Balance Overview</a></li>
                  <li _ngcontent-lst-c67="" ><a id="sub_menu_accountCashStatement_log" href="/exchange/displaymatch/AccountStatement">Account Statement
                  </a></li>
                  <li _ngcontent-lst-c67="" ><a id="sub_menu_my_bets" href="/exchange/displaymatch/mybets">My Bets</a></li>
                  <li _ngcontent-lst-c67="" ><a id="sub_menu_activity_log" href="/exchange/displaymatch/activitylog" class="select">Activity Log</a></li>
                  <li _ngcontent-lst-c67="" ><a id="sub_menu_activity_log" onClick={props.handlepassmodel} >Change password</a></li>
               </ul>
            </div>
         </div>

         <div _ngcontent-lst-c67="" class="centar-content report" >

            <div _ngcontent-lst-c67="" id="message" class="message-content success">
               <a _ngcontent-lst-c67="" class="close-button">Close</a>

            </div>

            <h2 _ngcontent-lst-c67="" >Activity Log
            </h2>

            <table _ngcontent-lst-c67="" class="table01">
               <tbody _ngcontent-lst-c67="" >
                  <tr _ngcontent-lst-c67="" >
                     <th style={{ width: '18%' }} class="align-L">Login Date &amp; Time
                     </th>
                     <th _ngcontent-lst-c67="" style={{ width: '30%' }} class="align-L">Login Status
                     </th>
                     <th _ngcontent-lst-c67="" style={{ width: '15%' }}>IP Address
                     </th>
                     <th _ngcontent-lst-c67="" style={{ width: " 28%" }} >ISP
                     </th>
                     <th _ngcontent-lst-c67="" >City/State/Country
                     </th>
                  </tr>
               </tbody>
               <tbody _ngcontent-lst-c67="" id="content">

                  {/* <tr _ngcontent-lst-c67="" >
                     <td _ngcontent-lst-c67="" class="no-data" colspan="5">
                        <p>No Data Found</p>
                     </td>
                  </tr> */}

                  {LogHistory.map(function (item, index) {
                     return (

                        <tr _ngcontent-fgb-c56="" id="tempTr">
                           <td _ngcontent-fgb-c56="" id="loginDate" class="align-L">{item.loginDateTime}</td>
                           <td _ngcontent-fgb-c56="" id="loginMessage" class="align-L green">{item.remark}</td>
                           <td _ngcontent-fgb-c56="" id="ipAddress">{item.ipAdress}</td>
                           <td _ngcontent-fgb-c56="" id="isp">{item.ISPN}</td>
                           <td _ngcontent-fgb-c56="" id="location">{item.Country}</td>
                        </tr>

                     )
                  })}


               </tbody>
            </table>



         </div>


      </React.Fragment>
   )
}
