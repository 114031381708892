import React from "react";
import { Link } from "react-router-dom";

const InPlayListItem = ({ pinId, pinOnClick, sportsId, eventId, marketId, linkOnClick, isTv, isFancy, isFancyClassName, isBookmaker, isBookmakerClassName, score, runnerName1, runnerName2, isPremium, isInplay, isLowLiquidity, time, liClassName }) => {

    return (
        <li
            // id="inPlayEvent_30062394"
            className={`${liClassName ? liClassName : 'inplay-on'}`}
        >
           
            <Link
                to={`/exchange/displaymatch/fullmarket/${sportsId}/${eventId}/${marketId}`}
                // id="info"
                onClick={linkOnClick}
            >
                
                <dl>
                    <dt>
                        {isTv && <span
                            // id="streamingIcon"
                            className="game-live"
                            style={{ display: "flex" }}
                        >
                            game-live
                        </span>}
                        {isLowLiquidity && <span className="game-low_liq"
                            style={{ display: 'none' }}>Low Liquidity</span>}
                        {isFancy && (
                            <span
                                // id="fancyBetIcon"
                                className={`game-fancy in-play ${isFancyClassName}`}
                                style={{ display: "flex" }}
                            >
                                <pre>in-play</pre>Fancy
                            </span>
                        )}

                        {isBookmaker &&
                            <span
                                // id="bookMakerIcon"
                                className={`game-bookmaker in-play ${isBookmakerClassName}`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}
                        {isPremium &&
                            <span className="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>}
                        {isInplay && (
                            <span
                                // id="inPlayStatus_30062394"
                                className="time">
                                In-Play
                            </span>
                        )}
                        {time && <span className="time">{time}</span>}
                    </dt>
                    <dd
                    // id="eventName"
                    >{runnerName1}
                        <span className="in_play">{score !== '' ? score : ' vs '}</span>
                        {runnerName2}</dd>
                </dl>
            </Link>
            <a id={pinId} className={`pin-off`} onClick={pinOnClick}></a>
        </li>
    )
}

export default InPlayListItem;
