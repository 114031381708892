import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import moment from 'moment'
import LoadingOverlay from "./components/loading-overlay/loading-overlay";
import InPlayListItem from "./components/in-play-list-item/in-play-list-item";
require('moment-timezone');

export default function Tommorrow(props) {

  const [cricket, setcricket] = useState([]);
  const [Soccer, setSoccer] = useState([]);
  const [Tennis, setTennis] = useState([]);
  const [Basketball, setBasketball] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [cricketUpdate, setcricketUpdate] = useState(false);




  useEffect(() => {

    axios.get(
      `https://liveapi247.live/api8/basketballData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {
          var tommorow = moment().add(1, 'day');


          if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            obj.push(item);
          }


        });


        setBasketball(obj);
      })
      .catch(error => console.log(error));
    axios.get(
      `https://liveapi247.live/api8/cricketData`)

      .then(result => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {

          var tommorow = moment().add(1, 'day');


          if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            obj.push(item);
          }

        });



        setcricket(obj);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api8/fancy`)

      .then(result => {

        setFancy(result.data);

      })


      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api8/tennisData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {
          var tommorow = moment().add(1, 'day');


          if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            obj.push(item);
          }


        });


        setTennis(obj);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api8/soccerData`)
      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {
          var tommorow = moment().add(1, 'day');


          if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            obj.push(item);
          }


        });


        setSoccer(obj);
        setrefresh(false);
      })
      .catch(error => console.log(error));

  }, [])





  return (

    <React.Fragment>

      <LoadingOverlay
        show={refresh === true}
      />

      {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0 || Basketball.length > 0) && refresh === false && <div className="mob-nav-highlight_list" id="inPlayData">

        {cricket.length > 0 && <div id="inPlayEventType_4" style={{ display: 'block' }}>
        <div class="game_icons">
							<img alt="" src="https://d1gvwx1uptx1i3.cloudfront.net/sporticon/icon/4-icon.png"/>
							<h3>Cricket</h3>
						</div>
          <ul className="mob-sports-highlight-list" id="inPlayList_4">
          <div class="game_ban"><img alt="" src="https://s3.ap-south-1.amazonaws.com/tokyo.assets/sporticon/4.png"/></div>
            {cricket.map(function (item, id) {

              let showtommorow = false, isFancy = false, showFancy = false, tommorow = moment().add(1, 'day');

              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showFancy = true;
              }

              if (fancy.includes(item.eventId)) {
                isFancy = true;
              }

              if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
                showtommorow = true;
              }


              return (
                <React.Fragment key={id}>

                  {
                    showtommorow
                    &&
                    <InPlayListItem
                      sportsId={4}
                      eventId={item.eventId}
                      marketId={item.marketId}
                      linkOnClick={() => props.changeState(true)}
                      isTv
                      isLowLiquidity
                      isPremium
                      isFancy={isFancy}
                      isBookmaker={isFancy}
                      isFancyClassName={showFancy ? "in-play" : "no-play"}
                      isBookmakerClassName={showFancy ? "in-play" : "no-play"}
                      runnerName1={item.eventName}
                     
                      time="Tomorrow"
                      liClassName="inplay-off"
                    />
                  }
                </React.Fragment>
              )
            })}
          </ul>
        </div>}

        {Soccer.length > 0 && <div id="inPlayEventType_1" style={{ display: 'block' }}>
        <div class="game_icons">
							<img alt="" src="https://d1gvwx1uptx1i3.cloudfront.net/sporticon/icon/1-icon.png"/>
							<h3>Football</h3>
						</div>
          <ul className="mob-sports-highlight-list" id="inPlayList_1">
          <div class="game_ban"><img alt="" src="https://s3.ap-south-1.amazonaws.com/tokyo.assets/sporticon/1.png"/></div>
            {Soccer.map(function (item, id) {
              let showtommorow = false, showFancy = false, tommorow = moment().add(1, 'day');

              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showFancy = true;
              }

              if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
                showtommorow = true;
              }

              return (
                <React.Fragment key={id}>
                  {showtommorow &&
                    <InPlayListItem
                      sportsId={1}
                      eventId={item.eventId}
                      marketId={item.marketId}
                      linkOnClick={() => props.changeState(true)}
                      isTv
                      isPremium
                      runnerName1={item.eventName}
                     
                   
                      time="Tomorrow"
                      liClassName="inplay-off"
                    />
                  }
                </React.Fragment>
              )
            })}

          </ul>
        </div>}


        {Tennis.length > 0 && <div id="inPlayEventType_2" style={{ display: 'block' }}>
        <div class="game_icons">
							<img alt="" src="https://d1gvwx1uptx1i3.cloudfront.net/sporticon/icon/2-icon.png"/>
							<h3>Tennis</h3>
						</div>
          <ul className="mob-sports-highlight-list" id="inPlayList_2">
          <div class="game_ban"><img alt="" src="https://s3.ap-south-1.amazonaws.com/tokyo.assets/sporticon/2.png"/></div>
            {Tennis.map(function (item, id) {
              // console.log('rendering');
              let showtommorow = false, showFancy = false, tommorow = moment().add(1, 'day');

              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showFancy = true;
              }

              if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
                showtommorow = true;
              }
              return (
                <React.Fragment key={id}>
                  {showtommorow &&
                    <InPlayListItem
                      sportsId={2}
                      eventId={item.eventId}
                      marketId={item.marketId}
                      linkOnClick={() => props.changeState(true)}
                      isPremium
                      runnerName1={item.eventName}
                    
                      time="Tomorrow"
                      liClassName="inplay-off"
                    />
                  }
                </React.Fragment>
              )
            })}

          </ul>
        </div>}


        {/* {Basketball.length > 0 && <div id="inPlayEventType_2" style={{ display: 'block' }}>
          <h3>Tennis</h3>
          <ul className="mob-sports-highlight-list" id="inPlayList_2">


            {Basketball.map(function (item, id) {
              console.log('rendering');
              var showtommorow = false;


              var showFancy = false;


              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showFancy = true;
              }


              var tommorow = moment().add(1, 'day');


              if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
                showtommorow = true;
              }
              return (
                <React.Fragment key={id}>
                  {showtommorow &&
                    <li  id="inPlayEvent_-10065496" key={id} className="inplay-off">
                      <Link  to={`/exchange/displaymatch/fullmarket/7522/${item.eventId}/${item.marketId}`} id="info" onClick={() => { props.changeState(true); }}>
                        <dl  >
                          <dt  >

                          <span id="sportsBookIcon_2" className="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>


                            <span  id="inPlayStatus_-10065496" className="time">Tomorrow</span>

                          </dt>
                          <dd  id="eventName">{item.eventName}</dd>
                        </dl>
                      </Link>
                      <a  id={MKPINTN + id} className="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
                    </li>
                  }
                </React.Fragment>
              )
            })}

          </ul>
        </div>} */}

      </div>}

      {(cricket.length === 0 && Soccer.length === 0 && Tennis.length === 0 && Basketball.length === 0) && refresh === false &&
        <div id="noMultiMarkets" className="no-data">
          <h3  >There are currently no matches tommorow.</h3>
          <p  >Please check upcoming matches.</p>
        </div>
      }
    </React.Fragment>
  )
}
