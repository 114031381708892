import React, { useState } from 'react'
import Today from './Today'
import Inplay from './Inplay'
import Tommorrow from './Tommorrow'
import SearchBar from './SearchBar'
import { Rusult } from "./Rusult"



export default function MobileInplay(props) {
    const [Eventday, setEventday] = useState(1)
    const [ResultTab, setResultTab] = useState(false)
    const [Searchbar, setSearchbar] = useState(false)



    const openSearchbar = () => {
        setSearchbar(true)
    }

    const closeSearchbar = () => {
        setSearchbar(false)
    }

    const ResultClickOpen = () => {
        setResultTab(true)
    }

    return (
        <React.Fragment>

            {ResultTab === false && <div className="mob-main-content">
                {Searchbar === true && <SearchBar closeSearchbar={closeSearchbar} />}
                <div style={{ display: "flex" ,height: '12vw'}} className="tab-wrap">

                    <ul>

                        <li id="inplay" onClick={() => { setEventday(1) }} className={`${(Eventday === 1) ? "select" : "null"}`}><a>In-Play</a></li>
                        <li id="today" onClick={() => { setEventday(2) }} className={`${(Eventday === 2) ? "select" : "null"}`}><a>Today</a></li>
                        <li id="tomorrow" onClick={() => { setEventday(3) }} className={`${(Eventday === 3) ? "select" : "null"}`}><a>Tomorrow</a></li>
                        {/* <li id="Result" onClick={() => { ResultClickOpen() }} className="null"><a >Result</a></li> */}

                    </ul>
                    <a className="a-search" onClick={openSearchbar} href="#"  style={{height: '12vw'}}>Search</a>
                </div>
                {Eventday === 1 && <Inplay isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
                {Eventday === 2 && <Today isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
                {Eventday === 3 && <Tommorrow isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}

            </div>}
            {/* {ResultTab === true && <Rusult changeState={props.changeState} />} */}
        </React.Fragment>
    )
}
