import React, { useState, useEffect } from 'react'
import Axios from 'axios';
import Transparent from '../images/sprite/transparent.gif'
import loading40 from "../images/sprite/loading40.gif"
import Left from './Left'
import Center from './Center'
import Betslipboard from './Betslipboard'
import Cookies from 'universal-cookie';
import BannerPage from './Bannerpage';



const cookies = new Cookies();
window.eventIId = '';
window.marketIId = '';
window.bType = 'LAGAI';
window.player_team = 'A';
window.odds_oneClick = '';


export default function Content(props) {

  const [showFullMarket, setFullMarket] = useState(false);
  const [Betslip, setBetslip] = useState(false)
  const [betClick, setbetClick] = useState(false)
  const [odds, setodds] = useState(0)
  const [onSelect, setonSelect] = useState(0)
  const [eventI, seteventI] = useState(' ')
  const [marketI, setmarketI] = useState(' ')
  const [minStack, setminStack] = useState(0)
  const [maxStack, setmaxStack] = useState(0)
  const [eventname, seteventname] = useState(' ')
  const [runnername, setrunnername] = useState(' ')
  const [openBets, setopenBets] = useState(false)
  const [loading, setloading] = useState(false);
  const [anyBets, setanyBets] = useState(false);
  const [anynewbets, setanynewbets] = useState(true)
  const [bets, setbets] = useState([])
  const [matchOption, setmatchOption] = useState(1)
  const [betVal1, setbetVal1] = useState([])
  const [betVal2, setbetVal2] = useState([])
  const [betVal3, setbetVal3] = useState([])
  const [betVal3F, setbetVal3F] = useState([])
  const [waiting, setwaiting] = useState(false)
  const [betMsg, setbetMsg] = useState({})


  const [dStake, setdStake] = useState(' ')
  const [team, setteam] = useState('A')
  const [betType, setbetType] = useState('LAGAI')






  const changedStake = (obj) => {
    //console.log(obj);

    setdStake(obj);

  };



  const changeteam = (obj) => {

    setteam(obj);

  };
  const changeOddType = (obj) => {

    setbetType(obj);

  };


  const changebetMsg = (obj) => {

    setbetMsg(obj);

  };


  const changeWaiting = (bool) => {

    setwaiting(bool);

  };

  const changeState = (obj) => {
    var obj1 = true;
    obj1 = obj;
    setFullMarket(obj1);
    props.changeTab(0);
  };

  const openbetslip = () => {
    setBetslip(!Betslip)
  }
  const isanybets = () => {
    setanyBets(!anyBets);
  }

  const openOpenBets = () => {
    setopenBets(!openBets)
  }

  const handleRefresh = () => {
    setloading(true);
    setTimeout(() => { setloading(false); }, 1000);
  }





  const changeBetMenu = (e, loc) => {
    if (loc === 1) {
      e.preventDefault();
    }
    var selectBox = document.getElementById("selectMenu");
    var selectedValue = selectBox.options[selectBox.selectedIndex].value;

    if (bets[selectedValue].marketName === 'Match Odds') {
      setmatchOption(2);

      Axios.post('https://liveapi247.live/api1/getbets', {
        id: props.userid,
        token: props.utoken,
        eventId: bets[selectedValue].eventId,
        marketId: bets[selectedValue].marketId,
        marketName: bets[selectedValue].marketName,

      })
        .then(result => {

          if (result.data) {

            var obj1 = [];
            var obj2 = [];
            for (let i = 0; i < result.data.Data1.length; i++) {
              if (result.data.Data1[i].type === 'LAGAI') {
                obj1.push(result.data.Data1[i])
              }
              else if (result.data.Data1[i].type === 'KHAI') {
                obj2.push(result.data.Data1[i])
              }
            }
            var obj3 = result.data.Data2;

            setbetVal1(obj1);
            setbetVal2(obj2);
            setbetVal3(obj3);

          }

        }

        ).catch(e => {
          // console.log(e);
        });


    }
    else if (bets[selectedValue].marketName === 'Bookmaker') {
      setmatchOption(3);
      Axios.post('https://liveapi247.live/api1/getbets', {
        id: props.userid,
        token: props.utoken,
        eventId: bets[selectedValue].eventId,
        marketId: bets[selectedValue].marketId,
        marketName: bets[selectedValue].marketName,

      })
        .then(result => {

          if (result.data) {

            let obj1 = [];
            let obj2 = [];

            for (let i = 0; i < result.data.Data1.length; i++) {
              if (result.data.Data1[i].type === 'LAGAI') {
                obj1.push(result.data.Data1[i])
              }
              else if (result.data.Data1[i].type === 'KHAI') {
                obj2.push(result.data.Data1[i])
              }
            }
            var obj3 = result.data.Data2;
            setbetVal1(obj1);
            setbetVal2(obj2);
            setbetVal3(obj3);


          }

        }

        ).catch(e => {

        });

    }
    else if (bets[selectedValue].marketName && bets[selectedValue].marketName != ' ') {
      setmatchOption(1);

      Axios.post('https://liveapi247.live/api1/getbets', {
        id: props.userid,
        token: props.utoken,
        eventId: bets[selectedValue].eventId,
        marketId: bets[selectedValue].marketId,
        marketName: bets[selectedValue].marketName,

      })
        .then(result => {

          if (result.data) {

            var obj1 = [];
            var obj2 = [];
            for (let i = 0; i < result.data.Data1.length; i++) {
              if (result.data.Data1[i].type === 'YES') {
                obj1.push(result.data.Data1[i])
              }
              else if (result.data.Data1[i].type === 'NO') {
                obj2.push(result.data.Data1[i])
              }
            }
            var obj3 = result.data.Data2;
            setbetVal1(obj1);
            setbetVal2(obj2);
            setbetVal3F(obj3);

          }


        }

        ).catch(e => {

        });

    }

  }




  const showBetForm = (val1, val2, val3, val4, val5, val6, val7, val8) => {

    setodds(val2);
    seteventI(val3);
    setmarketI(val4);
    setminStack(val5);
    setmaxStack(val6);
    seteventname(val7);
    setrunnername(val8);
    if (!val1) {
      setdStake(' ');
    }
    setbetClick(val1);

  }

  const setOnClick = (val) => {
    if (onSelect === val) {
      setonSelect(0);
      setbetClick(false);
    }
    else {
      setonSelect(val);
    }

    if (props.oneclick == true) {
      if (val === 1 || val === 3 || val === 5) {

        window.bType = 'LAGAI';
      }
      else if (val === 2 || val === 4 || val === 6) {

        window.bType = 'KHAI';

      }

      if (val === 1 || val === 2) {
        window.player_team = 'A';

      }
      else if (val === 3 || val === 4) {
        window.player_team = 'B';

      }
      else if (val === 5 || val === 6) {
        window.player_team = 'T';

      }
    }
  }

  const changeOdds = (val) => {
    setodds(val);

  }


  useEffect(() => {

    if (!props.userid) return;

    Axios.post('https://liveapi247.live/api1/getbetsinfo', {

      id: props.userid,
      token: props.utoken,

    })
      .then(result => {

        if (result.status === 210) {

          if (result.data.length > 0) {
            var obj = result.data;
            setanyBets(true);
            setanynewbets(false);
            setbets(obj);
            if (obj[0].marketName === 'Match Odds') {
              setmatchOption(2);
            }
            else if (obj[0].marketName === 'Bookmaker') {
              setmatchOption(3);
            }
            else if (obj[0].marketName && obj[0].marketName != ' ') {
              setmatchOption(1);
            }


          }
        }
      }

      ).catch(e => {

      });


  }, [anyBets, props.isLoggedIn]);



  useEffect(() => {
    setanyBets(!anyBets);
  }, []);

  useEffect(() => {
    if (document.getElementById("selectMenu")) {
      changeBetMenu(null, 2);
    }
  }, [bets]);




  const handleRefresh_oneclick = () => {

    const utoken = cookies.get('token');
    const user = cookies.get('id');
    Axios.post('https://liveapi247.live/api1/refresh', {
      id: user,
      token: utoken
    })
      .then(result => {
        if (result.status === 210) {


          if (result.data[0].exposureBalance) {
            props.updateBalance(result.data[0].mainBalance - Math.abs(result.data[0].exposureBalance), Math.abs(result.data[0].exposureBalance));

          }
          else {
            props.updateBalance(result.data[0].mainBalance - Math.abs(result.data[0].exposureBalance), 0);
            //props.changeExposure(0);
          }
        }

        if (result.status === 220) {
          // console.log('refresh unsucessful');

        }
      }

      ).catch(e => {
        //  setIsError(true);
      });
  };



  const oneClickMarket = () => {

    var dupBet = cookies.get('dupBet');
    Axios.post('https://liveapi247.live/api3/placebets', {

      clientId: props.userid,
      token: props.utoken,
      eventId: window.eventIId,
      marketId: window.marketIId,
      odds: window.odds_oneClick,
      oddsType: window.bType,
      betType: 'match',
      coin: window.oneclickStake,
      teamName: window.player_team,
      dupbet: dupBet

    }, { withCredentials: true })

      .then(result => {

        setwaiting(false);
        if (result.data == 6) {
          var obj = {};

          obj.msgNumber = 1;
          obj.msg = 'Bet Placed Successfully!';
          changebetMsg(obj);

          setTimeout(() => {
            changebetMsg({});
          }, 2000);
          isanybets(!anyBets);
          handleRefresh_oneclick();
        }
        else if (result.data == 1) {
          var obj = {};

          obj.msgNumber = 2;
          obj.msg = 'Bet Blocked';
          changebetMsg(obj);

          setTimeout(() => {
            changebetMsg({});
          }, 2000);
        }

        else if (result.data == 2) {

          var obj = {};

          obj.msgNumber = 2;
          obj.msg = 'Bet Closed !';
          changebetMsg(obj);

          setTimeout(() => {
            changebetMsg({});
          }, 2000);
        }
        else if (result.data == 3) {
          var obj = {};

          obj.msgNumber = 2;
          obj.msg = 'Insufficient Coin !';
          changebetMsg(obj);

          setTimeout(() => {
            changebetMsg({});
          }, 2000);
        }
        else if (result.data == 4) {
          var obj = {};

          obj.msgNumber = 2;
          obj.msg = 'Odds Changed !';
          changebetMsg(obj);

          setTimeout(() => {
            changebetMsg({});
          }, 2000);
        }
        else if (result.data == 5) {
          var obj = {};

          obj.msgNumber = 2;
          obj.msg = 'Bet can not be placed!';
          changebetMsg(obj);

          setTimeout(() => {
            changebetMsg({});
          }, 2000);
        }
        else if (result.data == 7) {
          var obj = {};

          obj.msgNumber = 2;
          obj.msg = 'Enter valid coin value!';
          changebetMsg(obj);

          setTimeout(() => {
            changebetMsg({});
          }, 2000);
        }
        else if (result.data == 123) {
          var obj = {};

          obj.msgNumber = 2;
          obj.msg = 'Bet Over 75 not accepted!';
          changebetMsg(obj);

          setTimeout(() => {
            changebetMsg({});
          }, 2000);
        }
        else if (result.data == 13) {
          var obj = {};

          obj.msgNumber = 2;
          obj.msg = 'Duplicate Bet Not allowed!';
          changebetMsg(obj);

          setTimeout(() => {
            changebetMsg({});
          }, 2000);
        }
        else if (result.data.charAt(0) == 8) {
          var obj = {};

          obj.msgNumber = 2;
          let arr = result.data.split('_');
          obj.msg = 'Min bet is' + ' ' + arr[1];
          changebetMsg(obj);

          setTimeout(() => {
            changebetMsg({});
          }, 2000);
        }
        else if (result.data.charAt(0) == 9) {
          var obj = {};

          obj.msgNumber = 2;
          let arr = result.data.split('_');
          obj.msg = 'Maximum bet is' + ' ' + arr[1];
          changebetMsg(obj);

          setTimeout(() => {
            changebetMsg({});
          }, 2000);
        }
        else {

          var obj = {};

          obj.msgNumber = 2;
          obj.msg = 'Bet can not be placed!';
          changebetMsg(obj);

          setTimeout(() => {
            changebetMsg({});
          }, 2000);
        }



      }

      ).catch(e => {


      });


  }




  return (
    <React.Fragment>
      <div class="main-content">
        {props.tabmenu === 1 && <BannerPage handleLogin={props.handleLogin} handleClose={props.handleClose} open={props.open} handleOpen={props.handleOpen} isLoggedIn={props.isLoggedIn} />}
        {/* {props.tabmenu === 14 && <Slots/>}
    {props.tabmenu === 15 && <Andarbahar/>}
    {props.tabmenu === 16 && <Baccarat/>}
    {props.tabmenu === 17 && <BlackJack/>}
    {props.tabmenu === 18 && <DragonTiger/>}
    {props.tabmenu === 19 && <Lucky7/>}
    {props.tabmenu === 20 && <Poker/>}
    {props.tabmenu === 21 && <Roullette/>}
    {props.tabmenu === 22 && <Sicbio/>} */}

        {props.tabMenu !== 2 && props.tabmenu !== 1 && props.tabmenu !== 14 && props.tabmenu !== 15 && props.tabmenu !== 16 && props.tabmenu !== 18 && props.tabmenu !== 19 && props.tabmenu !== 20 && props.tabmenu !== 21 && props.tabmenu !== 22 && props.tabmenu !== 2 && <Left changeState={changeState} />}
        {props.tabmenu !== 1 && props.tabmenu !== 14 && props.tabmenu !== 15 && props.tabmenu !== 16 && props.tabmenu !== 18 && props.tabmenu !== 19 && props.tabmenu !== 20 && props.tabmenu !== 21 && props.tabmenu !== 22 && <Center betType={betType} changeWaiting={changeWaiting} oneClickMarket={oneClickMarket} updateBalance={props.updateBalance} anyBets={anyBets} isanybets={isanybets} dStake={dStake} team={team} betClick={betClick} odds={odds} handleOpen={props.handleOpen} onSelect={onSelect} setOnClick={setOnClick} showBetForm={showBetForm} EventT={props.EventT} EventId={props.EventId} MarketId={props.MarketId} changeState={changeState} tabmenu={props.tabmenu} oneclick={props.oneclick} userid={props.userid} utoken={props.utoken} showFullMarket={showFullMarket} isLoggedIn={props.isLoggedIn} />}


        {props.tabmenu !== 1 && props.tabmenu !== 14 && props.tabmenu !== 15 && props.tabmenu !== 16 && props.tabmenu !== 18 && props.tabmenu !== 19 && props.tabmenu !== 20 && props.tabmenu !== 21 && props.tabmenu !== 22 && <div class="col-right">
          <div _ngcontent-lst-c69="" className={`slipbar-content ${(Betslip === true) ? "closed" : ""} ${(props.isLoggedIn === true && bets.length > 0) ? (Betslip === false && props.isLoggedIn) ? (openBets === false && props.isLoggedIn) ? "open-bet_slip" : "miniopen-bet_slip" : "no-open-bet_slip" : "showfull-bet_slip"}`}>
            <h3 _ngcontent-lst-c69=""   >
              <a _ngcontent-lst-c69="" id="eventType" onClick={openbetslip} style={{ textAlign: "left" }} className={`to-expand`}>Bet Slip</a>
            </h3>

            <div _ngcontent-lst-c69="" class="message-bet">
              {betMsg.msgNumber && betMsg.msgNumber === 1 && <h4 class="success" style={{}}>{betMsg.msg}</h4>}
              {betMsg.msgNumber && betMsg.msgNumber === 2 && <h4 class="error" style={{}}>{betMsg.msg}</h4>}
              {betMsg.msgNumber && betMsg.msgNumber === 3 && <h4 class="error" style={{}}>{betMsg.msg}</h4>}

            </div>

            {waiting &&
              <ul id="loadingMsg" class="loading" >
                <li><img src={loading40} /></li>
                <li id="countDownTime">Place Bets processing...</li>
              </ul>}



            {betClick === false && Betslip === false && !waiting && <p _ngcontent-lst-c69="" id="noBetSlipInfo">Click on the odds to add selections to the betslip.</p>}
            <div _ngcontent-lst-c69=""    >
              {betClick && Betslip === false &&
                <Betslipboard updateBalance={props.updateBalance} anyBets={anyBets} isanybets={isanybets} changeteam={changeteam} changeOddType={changeOddType} changedStake={changedStake} changebetMsg={changebetMsg} changeWaiting={changeWaiting} changeOdds={changeOdds} onSelect={onSelect} eventname={eventname} runnername={runnername} minStack={minStack} maxStack={maxStack} eventI={eventI} marketI={marketI} setOnClick={setOnClick} showBetForm={showBetForm} odds={odds} userid={props.userid} utoken={props.utoken} isLoggedIn={props.isLoggedIn} />
              }


            </div>
          </div>



          {props.isLoggedIn && bets.length > 0 && <div className={`matched-wrap ${(openBets === true) ? "close" : "null"}`} style={Betslip ? openBets ? { height: "calc(96%)" } : { height: "calc(96%)" } : { height: "calc(50%)" }}>
            <h3 _ngcontent-lst-c69=""   >
              <a _ngcontent-lst-c69="" id="openBetsRefresh" onClick={handleRefresh} class="slip_refresh" style={{ cursor: ' pointer' }}>Refresh</a>
              <a _ngcontent-lst-c69="" class="to-expand" onClick={openOpenBets} style={{ cursor: ' pointer' }}>Open Bets</a>
            </h3>
            <div _ngcontent-lst-c69="" class="message-bet">

            </div>
            <div _ngcontent-lst-c69="" class="message-bet">

            </div>
            {loading === true &&
              <ul id="loadingMsg" class="loading" >
                <li><img src={loading40} /></li>
                <li id="countDownTime">processing...</li>
              </ul>}
            {loading === false &&
              <div _ngcontent-lst-c69="" id="matchSection" style={{ height: 'calc(100% - 25px)' }}>
                <div _ngcontent-lst-c69="" id="openBetSelectionBox" class="slip-sort">
                  <select _ngcontent-lst-c69="" id="selectMenu" name="" onChange={(e) => { changeBetMenu(e, 1); }} >
                    {bets.map(function (item, index) {
                      return (
                        <option _ngcontent-lst-c69="" key={index} id="option_30058171_1_174059058_1" value={index} eventid="30058171" marketid="1.174059058" style={{ display: 'block' }} eventtype="4" categorytype="1" date="1603015817599" >{item.eventName + " - " + item.marketName}</option>
                      )
                    })}
                  </select>

                </div>


                {/*Fancy */}

                {matchOption === 1 && <div id="openBetSlip" class="bet_slip lit-content-center" style={{ height: ' calc(100% - 35px)' }}>


                  <ul _ngcontent-lst-c69="" id="txnHead" class="sports-header">
                    <li _ngcontent-lst-c69="" class="col-bet"><strong>Matched</strong>
                    </li>
                  </ul>
                  <div _ngcontent-lst-c69="" id="txnList" class="slip-list" name="txn">

                    {betVal1.length > 0 && <ul _ngcontent-lst-c69="" id="fancyYesHeader" class="matched-head">
                      <li _ngcontent-lst-c69="" class="col-bet">Yes</li>
                      <li _ngcontent-lst-c69="" id="runsOdds" class="col-odd">Runs/Odds</li>
                      <li _ngcontent-lst-c69="" class="col-stake">Stake</li>
                      <li _ngcontent-lst-c69="" class="col-profit">Profit</li>
                    </ul>}


                    {betVal1.map(function (item, index) {
                      if (betVal3F.length == 0) return null;
                      return (
                        <dl _ngcontent-lst-c69="" key={index} id="txn_349504415" class="slip-back" style={{ display: 'block' }} title="$10">
                          <dt _ngcontent-lst-c69="" class="slip-inline">
                            <span _ngcontent-lst-c69="" id="slipType" class="slip-type">YES</span><span class="short-amount">{betVal3F[0].runnerName}</span>
                            <span _ngcontent-lst-c69="" id="marketName"></span>
                          </dt>
                          <dd _ngcontent-lst-c69="" id="price" class="col-odd">{item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0)}</dd>
                          <dd _ngcontent-lst-c69="" id="size" class="col-stake">{item.amount}</dd>
                          <dd _ngcontent-lst-c69="" id="profitLiability" class="col-profit">{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</dd>
                        </dl>
                      )
                    })}


                    {betVal2.length > 0 && <ul _ngcontent-lst-c69="" id="fancyNoHeader" class="matched-head">
                      <li _ngcontent-lst-c69="" class="col-bet">No</li>
                      <li _ngcontent-lst-c69="" id="runsOdds" class="col-odd">Runs/Odds</li>
                      <li _ngcontent-lst-c69="" class="col-stake">Stake</li>
                      <li _ngcontent-lst-c69="" class="col-profit">Liability</li>
                    </ul>}

                    {betVal2.map(function (item, index) {
                      if (betVal3F.length == 0) return null;
                      return (
                        <dl _ngcontent-lst-c69="" key={index} id="txn_349503960" class="slip-lay" style={{ display: 'block' }} title="$10">
                          <dt _ngcontent-lst-c69="" class="slip-inline">
                            <span _ngcontent-lst-c69="" id="slipType" class="slip-type">No</span><span class="short-amount">{betVal3F[0].runnerName}</span>
                            <span _ngcontent-lst-c69="" id="marketName"></span>
                          </dt>
                          <dd _ngcontent-lst-c69="" id="price" class="col-odd">{item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0)}</dd>
                          <dd _ngcontent-lst-c69="" id="size" class="col-stake">{item.amount}</dd>
                          <dd _ngcontent-lst-c69="" id="profitLiability" class="col-profit">{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</dd>
                        </dl>
                      )
                    })}

                  </div>
                  <div _ngcontent-lst-c69="" class="full_btn">
                    <ul _ngcontent-lst-c69="" class="slip-option">
                      <li _ngcontent-lst-c69=""   >
                        <input _ngcontent-lst-c69="" id="showBetInfo" type="checkbox" name="openOption" />
                        <label _ngcontent-lst-c69="" htmlFor="showBetInfo">Bet Info</label>
                      </li>


                    </ul>
                  </div>
                </div>}
                {/*Fancy end */}


                {/* Match odds Start*/}
                {matchOption === 2 && <div id="openBetSlip" class="bet_slip lit-content-center" style={{ height: ' calc(100% - 35px)' }}>

                  <ul _ngcontent-lst-c69="" id="txnHead" class="sports-header">
                    <li _ngcontent-lst-c69="" class="col-bet"><strong>Matched</strong></li>

                  </ul>

                  <div _ngcontent-lst-c69="" id="txnList" class="slip-list" name="txn">

                    {betVal1.length > 0 && <ul id="backSlipHeader" class="matched-head">
                      <li _ngcontent-lst-c69="" class="col-bet">Back (Bet For)</li>
                      <li _ngcontent-lst-c69="" class="col-odd">Odds</li>
                      <li _ngcontent-lst-c69="" class="col-stake">Stake</li>
                      <li _ngcontent-lst-c69="" class="col-profit">Profit</li>
                    </ul>}


                    {betVal1.map(function (item, index) {
                      var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

                      if (betVal3.length == 0) return null;

                      return (
                        <dl _ngcontent-lst-c69="" key={index} id="txn_349521954" class="slip-back" style={{ display: 'block' }} betfairbetid="214218562743" sidetype="1">

                          <dt>
                            <span _ngcontent-lst-c69="" id="slipType" class="slip-type">Back</span><span class="short-amount">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</span>
                            <span _ngcontent-lst-c69="" id="marketName">Match Odds</span>
                          </dt>
                          <dd _ngcontent-lst-c69="" id="price" class="col-odd">{rate}</dd>
                          <dd _ngcontent-lst-c69="" id="size" class="col-stake" title="$10">{item.amount}</dd>
                          <dd _ngcontent-lst-c69="" id="profitLiability" class="col-profit">{parseFloat(parseFloat(item.rate) * parseFloat(item.amount)).toFixed(2)}</dd>
                        </dl>

                      )
                    })}



                    {betVal2.length > 0 && <ul id="laySlipHeader" class="matched-head">
                      <li _ngcontent-lst-c69="" class="col-bet">Lay (Bet Against)</li>
                      <li _ngcontent-lst-c69="" class="col-odd">Odds</li>
                      <li _ngcontent-lst-c69="" class="col-stake">Stake</li>
                      <li _ngcontent-lst-c69="" class="col-profit">Liability</li>
                    </ul>}


                    {betVal2.map(function (item, index) {
                      var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

                      if (betVal3.length == 0) return null;
                      return (
                        <dl _ngcontent-lst-c69="" key={index} id="txn_349522343" class="slip-lay" style={{ display: 'block' }} betfairbetid="214218587032" sidetype="2">

                          <dt>
                            <span _ngcontent-lst-c69="" id="slipType" class="slip-type">Lay</span><span class="short-amount">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</span>
                            <span _ngcontent-lst-c69="" id="marketName">Match Odds</span>
                          </dt>
                          <dd _ngcontent-lst-c69="" id="price" class="col-odd">{rate}</dd>
                          <dd _ngcontent-lst-c69="" id="size" class="col-stake" title="$10">{item.amount}</dd>
                          <dd _ngcontent-lst-c69="" id="profitLiability" class="col-profit">{parseFloat(parseFloat(item.rate) * parseFloat(item.amount)).toFixed(2)}</dd>
                        </dl>
                      )
                    })}



                    <div _ngcontent-lst-c69="" class="full_btn">
                      <ul _ngcontent-lst-c69="" class="slip-option">
                        <li _ngcontent-lst-c69=""   >
                          <input _ngcontent-lst-c69="" id="showBetInfo" type="checkbox" name="openOption" />
                          <label htmlFor="showBetInfo">Bet Info</label>
                        </li>


                      </ul>
                    </div>


                  </div>

                </div>}


                {matchOption === 3 && <div id="openBetSlip" class="bet_slip lit-content-center" style={{ height: ' calc(100% - 35px)' }}>

                  <ul _ngcontent-lst-c69="" id="txnHead" class="sports-header">
                    <li _ngcontent-lst-c69="" class="col-bet"><strong>Matched</strong></li>

                  </ul>



                  <div _ngcontent-lst-c69="" id="txnList" class="slip-list" name="txn">

                    {betVal1.length > 0 && <ul _ngcontent-lst-c69="" id="backSlipHeader" class="matched-head">
                      <li _ngcontent-lst-c69="" class="col-bet">Back (Bet For)</li>
                      <li _ngcontent-lst-c69="" class="col-odd">Odds</li>
                      <li _ngcontent-lst-c69="" class="col-stake">Stake</li>
                      <li _ngcontent-lst-c69="" class="col-profit">Profit</li>
                    </ul>}

                    {betVal1.map(function (item, index) {

                      if (betVal3.length == 0) return null;

                      return (
                        <dl _ngcontent-lst-c69="" key={index} id="txn_19863865" class="slip-back" style={{ display: 'block' }} title="$10">

                          <dt _ngcontent-lst-c69="" class="slip-inline">
                            <span _ngcontent-lst-c69="" id="slipType" class="slip-type">Back</span><span class="short-amount">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</span>
                            <span _ngcontent-lst-c69="" id="marketName"></span>
                          </dt>
                          <dd _ngcontent-lst-c69="" id="price" class="col-odd">{parseFloat(item.rate * 100).toFixed(2)}</dd>
                          <dd _ngcontent-lst-c69="" id="size" class="col-stake">{item.amount}</dd>
                          <dd _ngcontent-lst-c69="" id="profitLiability" class="col-profit">{parseFloat(parseFloat(item.rate) * parseFloat(item.amount)).toFixed(2)}</dd>
                        </dl>
                      )
                    })}

                    {betVal2.length > 0 && <ul id="laySlipHeader" class="matched-head">
                      <li _ngcontent-lst-c69="" class="col-bet">Lay (Bet Against)</li>
                      <li _ngcontent-lst-c69="" class="col-odd">Odds</li>
                      <li _ngcontent-lst-c69="" class="col-stake">Stake</li>
                      <li _ngcontent-lst-c69="" class="col-profit">Liability</li>
                    </ul>
                    }

                    {betVal2.map(function (item, index) {
                      if (betVal3.length == 0) return null;
                      return (
                        <dl key={index} id="txnTemplate" class="slip-lay">

                          <dt _ngcontent-lst-c69="" class="slip-inline">
                            <span _ngcontent-lst-c69="" id="slipType" class="slip-type" >Lay</span><span class="short-amount">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</span>
                            <span _ngcontent-lst-c69="" id="marketName"></span>
                          </dt>
                          <dd _ngcontent-lst-c69="" id="price" class="col-odd">{parseFloat(item.rate * 100).toFixed(2)}</dd>
                          <dd _ngcontent-lst-c69="" id="size" class="col-stake">{item.amount}</dd>
                          <dd _ngcontent-lst-c69="" id="profitLiability" class="col-profit">{parseFloat(parseFloat(item.rate) * parseFloat(item.amount)).toFixed(2)}</dd>
                        </dl>
                      )
                    })}


                  </div>


                  <div class="full_btn">
                    <ul class="slip-option">
                      <li><input id="showBetInfo" type="checkbox" name="openOption" /><label for="showBetInfo">Bet Info</label></li>

                    </ul>
                  </div>

                </div>}

              </div>}
          </div>}



        </div>}
      </div> </React.Fragment>

  )
}
