import React from 'react'
import Cup from '../images/sprite/cup.gif'
import Transparent from '../images/sprite/transparent.gif'
import { Link } from "react-router-dom";
import { Homeannounce } from './Homeannounce'
import Promot from '../images/sprite/promot.gif'
export default function Footer(props) {





	return (
		<div _ngcontent-njs-c65="" >
			
			{/* <nav>
			
				<ul class="mini-lobby">

					<li id="mini" className="main-nav">
						{props.isLoggedIn && <a onClick={() => { props.navcasinoclick(props.navbarcas) }} style={{ paddingTop: '4px' }} className="ui-link">
							<img className="icon-promote" src={Promot} alt="mini" />
						</a>}
						{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true) }} style={{ paddingTop: '4px' }} className="ui-link">
							<img className="icon-promote" src={Promot} alt="mini" />
						</a>}
					</li>



					<li id="sports">
						<Link _ngcontent-njs-c65="" to='/exchange/displaymatch/sports' className={`ui-link ${(props.tabMenu === 1) ? "select" : "null"}`}>
							<img className="icon-sports" src={Transparent} />Sports
						</Link></li>


					<li id="inPlay">
						<Link _ngcontent-njs-c65="" to='/exchange/displaymatch/inplay' className={`ui-link ${(props.tabMenu === 2) ? "select" : "null"}`} >
							<img class="icon-inplay" src={Transparent} />In-Play
						</Link></li>



					<li _ngcontent-njs-c65="" id="home">
						<Link _ngcontent-njs-c65="" to='/' className={`ui-link ${(props.tabMenu === 3) ? "select" : "null"}`}>
							<img class="icon-home" src={Transparent} />Home
						</Link></li>



					<li id="multiMarket">
						<Link _ngcontent-njs-c65="" to='/exchange/displaymatch/multimarket' className={`ui-link ${(props.tabMenu === 4) ? "select" : "null"}`}>
							<img class="icon-pin" src={Transparent} />Multi Markets
						</Link></li>
					<li id="account">
						{props.isLoggedIn && <Link to='/exchange/displaymatch/myaccount' className={`ui-link ${(props.tabMenu === 5) ? "select" : "null"}`}>
							<img class="icon-account" src={Transparent} />Account
						</Link>}
						{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true); props.changeTab(3) }} className="ui-link">	<img class="icon-account" src={Transparent} />Account</a>}
					</li>
				</ul>
			</nav> */}
			<nav className="cls-footer-sec sk-footer">
  <ul style={{marginBottom: '0px'}}>
    <li id="Exch"  className={`${(props.tabMenu === 1) ? "select" : "null"}`} >
      <Link  to='/exchange/displaymatch/sports'  className={`ui-link  ${(props.tabMenu === 1) ? "loading_" : "null"}`}  style={{color: '#fff', textDecoration: 'none'}}>
        <img src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/new_footer_icon/1.png" />Sports</Link>
    </li>
	<li id="inPlay" className={`${(props.tabMenu === 2) ? "select" : "null"}`}>
      <Link to='/exchange/displaymatch/inplay'  id="inPlay_open" className={`ui-link ${(props.tabMenu === 2) ? "loading_" : "null"}`}  style={{color: '#fff', textDecoration: 'none'}}>
        <img src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/new_footer_icon/4.png" />In-Play</Link>
      {/* <a href="JavaScript:void(0)" id="close-btn_inPlay" className="ui-link " style={{color: '#fff', textDecoration: 'none'}}>
        <i className="fa fa-close fa-2x" /></a> */}
    </li>
	<li id="home" className={`${(props.tabMenu === 3) ? "select" : "null"}`}>
      <Link to="/" className={`ui-link ${(props.tabMenu === 3) ? "loading_" : "null"}`}  style={{color: '#fff', textDecoration: 'none'}}>
        <img src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/new_footer_icon/3.png" />Home</Link>
    </li>
  
    <li id="sports1" className={`${(props.tabMenu === 4) ? "select" : "null"}`}  >
      <Link to='/exchange/displaymatch/multimarket'  className={`ui-link ${(props.tabMenu === 4) ? "loading_" : "null"}`} style={{color: '#fff', textDecoration: 'none'}}>
        <img src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/new_footer_icon/2.png" />multiMarket</Link>
    </li>
 
    <li id="account"  className={`${(props.tabMenu === 5) ? "select" : "null"}`}>
	{props.isLoggedIn && <Link to='/exchange/displaymatch/myaccount' className={`ui-link ${(props.tabMenu === 5) ? "loading_" : "null"}`} style={{color: '#fff', textDecoration: 'none'}}>
        <img src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/new_footer_icon/5.png" />
        Account
      </Link>}
	  {!props.isLoggedIn && <Link to='/exchange/displaymatch/myaccount'onClick={() => { props.checkShowLogin(true); props.changeTab(3) }} className="ui-link" style={{color: '#fff', textDecoration: 'none'}}>
        <img src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/new_footer_icon/5.png" />
        Account
      </Link>}
    </li>
  </ul>
</nav>

		</div>
	)
}
