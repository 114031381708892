import React from 'react'
import basketballjpg from '../images/kv/KV04.jpg'
import Matches from './BasketballMatches'


export default function Basketball(props) {
  return (
    <div _ngcontent-lst-c69="" >

      <div _ngcontent-lst-c69="" >
        <div _ngcontent-lst-c69="" class="images-grid">
          <img _ngcontent-lst-c69="" src={basketballjpg} alt="" />
        </div>
        <div _ngcontent-lst-c69="" id="gameHighlightWrap" style={{ marginBottom: '0' }} class="highlight-edge-content col3 ">
        <h3 style={{textAlign: 'left'}}>Sports Highlights<div class="highlight-sorting"><label for="lang">View by</label><div class="select"><select id="viewType" name="View"><option id="competitionName" value="competitionName" orderby="asc" priority="asc">Competition</option><option id="openDateTime" value="openDateTime" orderby="asc" inplay="desc">Time</option><option id="totalMatched" value="totalMatched" orderby="desc">Matched</option></select></div></div> </h3>
          <ul _ngcontent-lst-c69="" class="sports-header">
          <li _ngcontent-lst-c69="" class="col-game" style={{ width: 'calc(64% - -62px)' }}></li>
          <li _ngcontent-lst-c69="" class="sport-odds">1</li>
          {/* <li _ngcontent-lst-c69="" class="col-draw">x</li> */}
          <li _ngcontent-lst-c69="" class="last-col">2</li>
          <li _ngcontent-lst-c69="" class="info-icon"></li>
        </ul>
        <Matches changeState={props.changeState} />

        </div>
       
      </div>
    </div>
  )
}
