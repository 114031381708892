import React, { useState } from 'react'
import InplayContent from './InplayContent'
import Today from './Today'
import Tommorow from './Tomorrow'
import Loading from "../images/sprite/loading40.gif"




export default function InplayDesktop(props) {
   const [Eventday, setEventday] = useState(1)
   const [loading, setloading] = useState(false);



   const handleRefresh = () => {
      setloading(true);

   }


   return (

      <div>
         {loading === true && <div id="oneClickLoading" class="loading-wrap" ><ul class="loading"><li><img src={Loading} alt="Place_bet" /></li><li>Loading...</li></ul></div>}
         <div>

            <div _ngcontent-njs-c64="" id="centerColumn" class="inplay">

               <div _ngcontent-njs-c64="" >
                  <ul _ngcontent-njs-c64="" class="tab col3">
                     <li _ngcontent-njs-c64="" onClick={() => { setEventday(1) }}><a className={`${(Eventday === 1) ? "select" : "null"}`}>In-Play</a></li>
                     <li _ngcontent-njs-c64="" onClick={() => { setEventday(2) }}><a className={`${(Eventday === 2) ? "select" : "null"}`}>Today</a></li>
                     <li _ngcontent-njs-c64="" onClick={() => { setEventday(3) }}><a className={`${(Eventday === 3) ? "select" : "null"}`}>Tomorrow</a></li>
                  </ul>
               </div>

               {Eventday === 1 && <InplayContent changeState={props.changeState} />}
               {Eventday === 2 && <Today changeState={props.changeState} />}
               {Eventday === 3 && <Tommorow changeState={props.changeState} />}

            </div>
         </div>

      </div>
   )
}
