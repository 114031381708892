import axios from 'axios';

const baseApi1 = axios.create({
  baseURL: 'https://liveapi247.live',
});

export const getGameBalanceApiRq = async ({
  providercode,
  username
}) => {
  const response = await baseApi1.post('api1/getBalance', {
    providercode,
    username
  });
  return response;
};

export const refreshBalanceApiRq = async ({
  id,
  token
}) => {
  const response = await baseApi1.post('api1/refresh', {
    id,
    token,
  });
  return response;
};

export const enterCasinoTransactionApiRq = async ({
  username, 
  transferType, 
  providercode,
  amount,
  token,
}) => {
  const type = transferType === 'd' ? "0" : transferType === 'w' ? "1" : null;
  const response = await baseApi1.post('api1/enterCasinoTransaction_2', {
    username, 
    type, 
    providercode,
    amount,
    token,
  });
  return response;
};
