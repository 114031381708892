import React, { memo } from 'react';


const LoadingOverlay = ({ show, msg = 'Loading...', style }) => {
    if (show) {
        return (
     	<div class="loading-overlay" style={{display: 'flex'}} id="loader__">
            <div class="loading-wrap" style={{display: 'flex'}}>
                <div class="loading">
                    <div></div>
                    <div></div>
                </div>
                <p>{msg}</p>
            </div>
        </div>
        );
    }
    return null;
};

export default memo(LoadingOverlay);
