import React from 'react'

export default function ConfirmBet(props) {
    return (
        <React.Fragment>
            <div id="confirmBetPop" _ngcontent-njs-c67=""  class="overlay" style={{display: "flex"}}>
                <div _ngcontent-njs-c67=""  class="pop-wrap">
                    <h3 _ngcontent-njs-c67="" >Please Confirm Your Bet
        </h3>

                    <dl _ngcontent-njs-c67=""  class="info-bet">
            <dt>
                            {props.oddsType === 'BACK' && <span _ngcontent-njs-c67="" id="sideType" class="back">{props.oddsType}</span>}
                            {props.oddsType === 'LAY' && <span _ngcontent-njs-c67=""  id="sideType" class="lay">{props.oddsType}</span>}
          <h4 id="runnerName">{props.runnerName}</h4>
            </dt>
            <dd>
                <span>Odds</span>
                            <strong _ngcontent-njs-c67=""  id="odds">{props.mOdds}</strong>
            </dd>
            <dd>
                <span>Stake</span>
                            <strong _ngcontent-njs-c67=""  id="stake">{props.coin}</strong>
            </dd>
            <dd>
                            <span _ngcontent-njs-c67=""  id="plName">{props.oddsType === 'BACK' ? 'Profit' : 'Liability'}</span>
                            <strong _ngcontent-njs-c67=""  id="pl">{((parseFloat(props.mOdds)-1)*props.coin).toFixed(2)}

                </strong>
            </dd>
          </dl>

                    <ul _ngcontent-njs-c67=""  class="btn-list">
                        <li _ngcontent-njs-c67="" >
                            <a id="back" _ngcontent-njs-c67=""   class="btn" onClick = {()=>{props.closeConfirmBet();}}>Back
                </a>
            </li>
            <li>
                            <a _ngcontent-njs-c67="" id="confirm" class="btn-send" onClick = {(e)=>{e.preventDefault(); props.marketBet(); props.closeConfirmBet();
                                // props.handleRefreshhh(5000,'Placing bet...',1)
                            }} >Confirm
                </a>
            </li>
         </ul>
       </div>
     </div>
        </React.Fragment>
    )
}
