import React, { useState, useEffect } from "react";
import Loading from '../images/sprite/loading40.gif'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios';
import { toast } from 'react-toastify'
import DatePicker from "react-datepicker";
import moment from 'moment';
require('moment-timezone');
window.pnlView = 1;
window.daypnl = 3;

var globalArray1 = [];

toast.configure()

export default function Balanceoverview(props) {
	const [LedgerData, setLedgerData] = useState([])
	const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
	const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
	const [startDate, setStartDate] = useState(moment().toDate());
	const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
	const [Refresh, setRefresh] = useState(false);

	var NOW = moment().subtract(7, 'days').toDate();

	const GetLedgerData = () => {

		var start = '';
		var end = '';

		if (window.day === 3) {
			var now = moment().subtract(7, 'days');
			start = now.startOf('day').format("YYYY-MM-DD");
			end = moment().endOf('day').format("YYYY-MM-DD");
		}
		else if (window.day === 1) {
			var now = moment();
			start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
			end = now.endOf('day').format("YYYY-MM-DD HH:mm:ss");


		}
		else if (window.day === 2) {
			var now = moment().subtract(1, 'days');
			start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
			end = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");


		}
		globalArray1 = [];
		setRefresh(true);
		axios.post('https://liveapi247.live/api1/balanceoverview', {
			id: props.userid,
			token: props.utoken,
			fromdate: start,
			todate: end,
		})

			.then(result => {
				if (result.data.length == 0) {
					toast.warn('You have no bets in this periods!', { position: toast.POSITION.TOP_CENTER })
				}

				if (result.status === 200) {

					for (let key in result.data) {
						if (result.data.hasOwnProperty(key)) {
							result.data[key].user = key;
							globalArray1.push(result.data[key]);
							//    console.log(result.data[key],'data');  
						}
					}
					setRefresh(false)
					let arr = [];
					globalArray1.map((item) => {
						arr.push(item);
					});
					setLedgerData(arr)
				}

			}

			).catch(e => {
				//setIsError(true);
			});

	}

	return (
		<React.Fragment>

			<div _ngcontent-njs-c67="" class="col-left">
				<div _ngcontent-njs-c67="" class="sub_path">
					<div _ngcontent-njs-c67="" class="path">

						<ul>
							<li _ngcontent-njs-c67="" class="path-last"><a _ngcontent-njs-c67="" href="/#">My Account</a></li>
						</ul>
					</div>
					<ul _ngcontent-njs-c67="" id="path-pop" class="path-pop">
					</ul>
					<ul _ngcontent-njs-c67="" class="sports-side-menu-list">
						<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="sub_menu_detail" href="/exchange/displaymatch/myprofile">My Profile</a></li>
						<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="sub_menu_summary" href="/exchange/displaymatch/balance" class="select">Balance Overview</a></li>
						<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="sub_menu_accountCashStatement_log" href="/exchange/displaymatch/AccountStatement">Account Statement
						</a></li>
						<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="sub_menu_my_bets" href="/exchange/displaymatch/mybets">My Bets</a></li>
						<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="sub_menu_activity_log" href="/exchange/displaymatch/activitylog">Activity Log</a></li>
						<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="sub_menu_activity_log" onClick={props.handlepassmodel} >Change password</a></li>
					</ul>
				</div>
			</div>
			<div _ngcontent-njs-c67="" class="centar-content report">
				{Refresh &&
					<ul class="loading">
						<li><img src={Loading} /></li>
						<li>Loading...
						</li>
						<br />
						<li><span id="progress"></span></li>
					</ul>

				}
				<div _ngcontent-njs-c67="" id="message" class="message-content success">
					<a _ngcontent-njs-c67="" href="/#" class="close-button">Close</a>
					<p></p>
				</div>
				<h2>Summary</h2>
				<div _ngcontent-njs-c67="" class="white-wrap">
					<dl _ngcontent-njs-c67="" class="head-balance">
						<dt>Your Balances</dt>
						<dd _ngcontent-njs-c67="" id="summaryTotalBalance">{props.balance.toFixed(2)} <span>USD</span></dd>
					</dl>
					<h3>Welcome,</h3>
					<p>View your account details here. You can manage funds, review and change your settings and see the performance of your betting activity.</p>
				</div>

				<ul _ngcontent-njs-c67="" className="input-list">
					<li _ngcontent-njs-c67="" ><label>Period</label></li>
					<li _ngcontent-njs-c67="" >
						<ul _ngcontent-njs-c67="" className="input-list" style={{ display: 'inline-flex' }}>
							<DatePicker
								selectsStart
								dateFormat="yyyy-MM-dd"
								selected={startDate}
								placeholderText="YYYY-MM-DD"
								minDate={NOW}
								className="cal-input"
								onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
							/>
							<input id="startTime" disabled="true" class="time-input disable" type="text" placeholder="09:00" maxlength="5" />

							<DatePicker
								selectsEnd
								dateFormat="yyyy-MM-dd"
								placeholderText="YYYY-MM-DD"
								selected={endDate}
								onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
								className="cal-input"
							/>
							<input id="endTime" disabled="true" class="time-input disable" type="text" placeholder="08:59" maxlength="5" />


						</ul>

					</li>

					<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="getPL" onClick={() => { window.day = 3; window.betHistoryView = 1; GetLedgerData(); }} className="btn-send">Get Data</a></li>
				</ul>



				<table class="table01">
					<tbody><tr>
						<th width="13%" class="align-L">Date</th>
						<th width="30%" class="align-L">Description №</th>
						<th width="13%">Debits</th>
						<th width="13%">Credits</th>
						<th width="13%">Balance</th>
						<th>Remarks</th>

					</tr>
						{LedgerData.map((item, index) => {


							var DebitBalance;
							var CreditBalance;

							if (!isNaN(parseFloat(item.Debit))) {
								DebitBalance = parseFloat(item.Debit).toFixed(2);
							}
							if (!isNaN(parseFloat(item.Credit))) {
								CreditBalance = parseFloat(item.Credit).toFixed(2);
							}

							var BalanceValue = parseFloat(item.Balance).toFixed(2);
							item.TrDate = moment.tz(item.TrDate, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');

							return (
								<tr key={index} id="dataTemplate">
									<td id="date" class="align-L">{item.TrDate}</td>
									<td id="statementSerial" class="align-L"><strong>{item.EventName}</strong></td>
									<td id="debits"><span _ngcontent-njs-c67="" className={`${DebitBalance >= 0 ? "" : "red"}`}>{DebitBalance >= 0 ? DebitBalance : '(' + Math.abs(DebitBalance) + ')'}</span></td>
									<td id="credits">{CreditBalance}</td>
									<td id="balance">{BalanceValue}</td>
									<td id="remarks">{item.Commant}</td>
									{/* <td id="fromTo"><img  _ngcontent-njs-c67="" class="fromto" src={Transparent} alt="gif"/></td> */}
								</tr>
							)
						})}


					</tbody>
					{/* {LedgerData.length == 0 &&
			<tbody id="Matched_Content">
				<tr id="noDataTemplate" style={{display: "table-row"}}>
				<td class="no-data" colspan="7"><p>Select date to see your Balance Overview past 7 Days</p></td></tr>
				</tbody>} */}
				</table>
				<script type="text/javascript" src="/js/util/PageUtil.js?v=20200907_1599446400000"></script>
				{LedgerData.length > 0 &&
					<div><ul _ngcontent-njs-c67="" id="pageNumberContent" className="pages"><li _ngcontent-njs-c67="" id="prev"><a _ngcontent-njs-c67="" className="disable">Prev</a></li>
						<li _ngcontent-njs-c67="" id="pageNumber"><a _ngcontent-njs-c67="" className="select" style={{ pointerEvents: 'none' }}>1</a>
						</li><li _ngcontent-njs-c67="" id="next"><a _ngcontent-njs-c67="" className="disable">Next</a></li></ul></div>}

			</div>
		</React.Fragment>

	)
}
