import React, { useState, useEffect } from 'react'
import Axios from 'axios';

export default function OpenBets(props) {

	const [matchOption, setmatchOption] = useState(1)
	const [view, setview] = useState(1)
	const [bets, setbets] = useState([])
	const [betVal1, setbetVal1] = useState([])
	const [betVal2, setbetVal2] = useState([])
	const [betVal3, setbetVal3] = useState([])
	const [betVal3F, setbetVal3F] = useState([])
	const [menuName, setmenuName] = useState('')

	const changeBetMenu = (obj) => {

		//e.preventDefault();
		if (!props.userid) return;
		if (obj.marketName === 'Match Odds') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Tied Match') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//	console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Completed Match') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Super Over') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Over/Under 0.5 Goals') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Over/Under 1.5 Goals') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Over/Under 2.5 Goals') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Over/Under 3.5 Goals') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});

		}
		else if (obj.marketName === 'Over/Under 4.5 Goals') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});

		}
		else if (obj.marketName === 'To Win the Toss') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Bookmaker') {
			setmatchOption(3);
			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {

					if (result.data) {

						let obj1 = [];
						let obj2 = [];

						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);


					}

				}

				).catch(e => {
					console.log(e);
				});

		}
		else if (obj.marketName === 'Moneyline') {
			setmatchOption(3);
			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {

					if (result.data) {

						let obj1 = [];
						let obj2 = [];

						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);


					}

				}

				).catch(e => {
					console.log(e);
				});

		}
		else if (obj.betType === 'premium') {
			setmatchOption(13);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {

					if (result.data) {

						let obj1 = [];
						let obj2 = [];

						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							// else if (result.data.Data1[i].type === 'KHAI') {
							// 	obj2.push(result.data.Data1[i])
							// }
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);


					}

				}

				).catch(e => {
					console.log(e);
				});

		}


		else if (obj.marketName && obj.marketName != ' ') {
			setmatchOption(1);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'YES') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'NO') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3F(obj3);

					}


				}

				).catch(e => {
					console.log(e);
				});

		}

	}
	useEffect(() => {

		if (!props.userid) return;

		Axios.post('https://liveapi247.live/api1/getbetsinfo', {

			id: props.userid,
			token: props.utoken,

		})
			.then(result => {
				//console.log('bets', result.data);
				if (result.data.length > 0) {
					var obj = result.data;

					setbets(obj);
					if (obj[0].marketName === 'Match Odds') {
						setmatchOption(2);
					}
					else if (obj[0].marketName === 'Tied Match') {
						setmatchOption(4);
					}
					else if (obj[0].marketName === 'Completed Match') {
						setmatchOption(5);
					}
					else if (obj[0].marketName === 'Super Over') {
						setmatchOption(6);
					}
					else if (obj[0].marketName === 'Over/Under 0.5 Goals') {
						setmatchOption(7);
					}
					else if (obj[0].marketName === 'Over/Under 1.5 Goals') {
						setmatchOption(8);
					}
					else if (obj.marketName === 'Over/Under 2.5 Goals' || obj.marketName === 'Over/Under 3.5 Goals' || obj.marketName === 'Over/Under 4.5 Goals') {
						setmatchOption(9);
					}
					else if (obj[0].marketName === 'To Win the Toss') {
						setmatchOption(12);
					}
					else if (obj[0].marketName === 'Moneyline') {
						setmatchOption(10);
					}
					else if (obj[0].marketName === 'Bookmaker') {
						setmatchOption(3);
					}
					else if (obj[0].marketName && obj[0].marketName != ' ') {
						setmatchOption(1);
					}
					else if (obj[0].betType === 'premium') {
						setmatchOption(13);
					}

				}

			}

			).catch(e => {
				//	console.log(e);
			});

		/*  if(anyBets){

		  }
		   */
	}, [props.userid]);

	const openBetHistry = (obj) => {

		changeBetMenu(obj);
		setmenuName(obj.eventName + " - " + obj.marketName);
		if (obj.marketName === 'Match Odds') {
			setmatchOption(2);
		}
		else if (obj.marketName === 'Tied Match') {
			setmatchOption(4);
		}
		else if (obj.marketName === 'Completed Match') {
			setmatchOption(5);
		}
		else if (obj.marketName === 'Super Over') {
			setmatchOption(6);
		}
		else if (obj.marketName === 'Over/Under 0.5 Goals') {
			setmatchOption(7);
		}
		else if (obj.marketName === 'Over/Under 1.5 Goals') {
			setmatchOption(8);
		}
		else if (obj.marketName === 'Over/Under 2.5 Goals' || obj.marketName === 'Over/Under 3.5 Goals' || obj.marketName === 'Over/Under 4.5 Goals') {
			setmatchOption(9);
		}
		else if (obj.marketName === 'To Win the Toss') {
			setmatchOption(12);
		}
		else if (obj.marketName === 'Moneyline') {
			setmatchOption(10);
		}
		else if (obj.betType === 'premium') {
			setmatchOption(13);
		}
		else if (obj.marketName === 'Bookmaker') {
			setmatchOption(3);
		}
		else if (obj.marketName && obj.marketName != ' ') {
			setmatchOption(1);
		}
	}



	return (
		<React.Fragment>

			{view === 1 &&
				<ul _ngcontent-njs-c67="" id="eventMenuList" class="menu-list">

					{bets && bets?.map(function (item, index) {
						return (
							<li _ngcontent-njs-c67="" key={index} onClick={() => { openBetHistry(item); setview(2); }} id="eventMenuTemplate" class="inplay-off"><a _ngcontent-njs-c67="" style={{ display: 'block' }}>{item.eventName + " - " + item.marketName}</a></li>
						)
					})}

				</ul>}



			{view === 2 &&
				<React.Fragment>
					<div _ngcontent-njs-c67="" id="pathWrap" class="path-wrap" style={{}}>
						<a _ngcontent-njs-c67="" id="goBack" class="go_back" onClick={() => { setview(1); }}></a>

						<ul>
							<li _ngcontent-njs-c67="" id="eventName">{menuName}</li>
						</ul>
					</div>
				</React.Fragment>}


			{/* {view === 2 &&
				<React.Fragment>

					<h3  _ngcontent-njs-c67="" id="txnHeader" >
						Unmatched
						<a  _ngcontent-njs-c67=""  id="cancelAll" class="trashcan">Cancel All</a>
					</h3>


					{matchOption === 2 &&
						<React.Fragment>

							<div  _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl  _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4  _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd  _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd  _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd  _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl  _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span  _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<a  _ngcontent-njs-c67=""  id="editBtn" class="a-edit" href="#">Edit</a>
												<h4  _ngcontent-njs-c67=""  id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd  _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd  _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd  _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd  _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li  _ngcontent-njs-c67=""  id="betId"></li>
													<li  _ngcontent-njs-c67=""  id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl  _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4  _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd  _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd  _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd  _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl  _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span  _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4  _ngcontent-njs-c67=""  id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd  _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd  _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd  _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd  _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li  _ngcontent-njs-c67=""  id="betId"></li>
													<li  _ngcontent-njs-c67=""  id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul  _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'none' }}>
								<li  _ngcontent-njs-c67="" ><a  _ngcontent-njs-c67=""  id="showBetInfo" class="" >Bet Info</a></li>
								<li  _ngcontent-njs-c67=""  name="txnOption" style={{ display: 'none' }}><a  _ngcontent-njs-c67=""  id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}





					{matchOption === 3 &&
						<React.Fragment>

							<div  _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>


								{betVal1.length > 0 && <dl  _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4  _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd  _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd  _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd  _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>}

								{betVal1.map(function (item, index) {

									if (betVal3.length == 0) return null;

									return (

										<dl  _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span  _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4  _ngcontent-njs-c67=""  id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd  _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{item.rate * 100}</span></dd>
											<dd  _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd  _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd  _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li  _ngcontent-njs-c67=""  id="betId"></li>
													<li  _ngcontent-njs-c67=""  id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}


								{betVal2.length > 0 && <dl  _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4  _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd  _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd  _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd  _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									if (betVal3.length == 0) return null;
									return (

										<dl  _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span  _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4  _ngcontent-njs-c67=""  id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd  _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{item.rate * 100}</span></dd>
											<dd  _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd  _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd  _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li  _ngcontent-njs-c67=""  id="betId"></li>
													<li  _ngcontent-njs-c67=""  id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}

							</div>

							<ul  _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'none' }}>
								<li  _ngcontent-njs-c67="" ><a  _ngcontent-njs-c67=""  id="showBetInfo" class="" >Bet Info</a></li>
								<li  _ngcontent-njs-c67=""  name="txnOption" style={{ display: 'none' }}><a  _ngcontent-njs-c67=""  id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}






					{matchOption === 1 &&
						<React.Fragment>

							<div  _ngcontent-njs-c67="" id="txnList" class="slip-list" >


								{betVal1.length > 0 && <dl  _ngcontent-njs-c67="" id="header_yes" class="bets-head">
									<dt>
										<h4  _ngcontent-njs-c67="" >Yes</h4>
									</dt>
									<dd  _ngcontent-njs-c67="" id="runsOdds" class="col-odds">Runs/Odds</dd>
									<dd  _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd  _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>}

								{betVal1.map(function (item, index) {
									if (betVal3F.length == 0) return null;
									return (

										<dl  _ngcontent-njs-c67="" key={index} id="txnTemplate" class="bets-back" >
											<dt>
												<span  _ngcontent-njs-c67="" id="sideType" class="back">Yes</span>
												<h4  _ngcontent-njs-c67=""  id="selectionName">{betVal3F[0].runnerName}</h4>
											</dt>
											<dd  _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{item.rate}<br />{parseFloat(item.teamName * 100).toFixed(0)}</span></dd>
											<dd  _ngcontent-njs-c67="" id="stake" class="col-stake"><span>{item.amount}</span></dd>
											<dd  _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</span></dd>
											<dd  _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li  _ngcontent-njs-c67=""  id="betId"></li>
													<li  _ngcontent-njs-c67=""  id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}


								{betVal2.length > 0 && <dl  _ngcontent-njs-c67="" id="header_no" class="bets-head" >
									<dt>
										<h4  _ngcontent-njs-c67="" >No</h4>
									</dt>
									<dd  _ngcontent-njs-c67="" id="runsOdds" class="col-odds">Runs/Odds</dd>
									<dd  _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd  _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									if (betVal3F.length == 0) return null;
									return (
										<dl  _ngcontent-njs-c67="" key={index} id="txnTemplate" class="bets-lay" >
											<dt>
												<span  _ngcontent-njs-c67="" id="sideType" class="lay">No</span>
												<h4  _ngcontent-njs-c67=""  id="selectionName">{betVal3F[0].runnerName}</h4>
											</dt>
											<dd  _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{item.rate}<br />{parseFloat(item.teamName * 100).toFixed(0)}</span></dd>
											<dd  _ngcontent-njs-c67="" id="stake" class="col-stake"><span>{item.amount}</span></dd>
											<dd  _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</span></dd>
											<dd  _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li  _ngcontent-njs-c67=""  id="betId"></li>
													<li  _ngcontent-njs-c67=""  id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}


							</div>

							<ul  _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'none' }}>
								<li  _ngcontent-njs-c67="" ><a  _ngcontent-njs-c67=""  id="showBetInfo" class="" >Bet Info</a></li>
								<li  _ngcontent-njs-c67=""  name="txnOption" style={{ display: 'none' }}>
									<a  _ngcontent-njs-c67=""  id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}
				</React.Fragment>

				} */}

			{view === 2 &&
				<React.Fragment>
					<h3 _ngcontent-njs-c67="" id="txnHeader" >
						Matched
					</h3>
					{matchOption === 2 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
									if (betVal3.length == 0) return null;
									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{matchOption === 4 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{matchOption === 5 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{matchOption === 6 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}
					{/* 0.5 under */}
					{matchOption === 7 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{/* 1.5 under */}

					{matchOption === 8 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{/* 2.5 under   */}

					{matchOption === 9 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2?.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{/* {basketball} */}
					{matchOption === 10 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal1?.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{betVal3[0].runnerName}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}


					{matchOption === 12 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}


				{/* // premium */}


						{matchOption === 13 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal3?.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal3?.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.runnerName}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}
					{matchOption === 3 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>


								{betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>}

								{betVal1?.map(function (item, index) {

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{item.rate * 100}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}


								{betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2?.map(function (item, index) {
									if (betVal3.length == 0) return null;
									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{item.rate * 100}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{matchOption === 1 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" >


								{betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_yes" class="bets-head">
									<dt>
										<h4 _ngcontent-njs-c67="" >Yes</h4>
									</dt>
									<dd _ngcontent-njs-c67="" id="runsOdds" class="col-odds">Runs/Odds</dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>}

								{betVal1?.map(function (item, index) {
									if (betVal3F.length == 0) return null;
									return (

										<dl _ngcontent-njs-c67="" key={index} id="txnTemplate" class="bets-back" >
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Yes</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{betVal3F[0].runnerName}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{item.rate}<br />{parseFloat(item.teamName * 100).toFixed(0)}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}


								{betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_no" class="bets-head" >
									<dt>
										<h4 _ngcontent-njs-c67="" >No</h4>
									</dt>
									<dd _ngcontent-njs-c67="" id="runsOdds" class="col-odds">Runs/Odds</dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2?.map(function (item, index) {
									if (betVal3F.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txnTemplate" class="bets-lay" >
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">No</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{betVal3F[0].runnerName}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{item.rate}<br />{parseFloat(item.teamName * 100).toFixed(0)}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}


							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}>
									<a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}
				</React.Fragment>}

		</React.Fragment>
	)
}
