import React, {memo, useEffect} from 'react';
import { marketKeys } from '../../utils';

const PlaceBetKeybord = ({ renderBottom, odds, oddInputDisable, onClickOddMinus, onClickOddPlus, onClickStackMinus, onClickStackPlus, defaultStake, layBack, onCancel, onPlace, minStack, coinFactor, onInputChnageStack, ssStack = {}, style, onChnageStakeValue = ()=> null}) => {
    useEffect(() => {
        onChnageStakeValue(defaultStake)
    }, 
    
    [defaultStake]);

    useEffect(() => {
        setTimeout(()=>{
            onCancel();
        },9000)
    }, []);

    return (
        <div style={{ ...style }} className={`bet_slipbar-content ${layBack}`}>
            <ul className="btn-list">
                <li style={{ flex: 1}}>
                    <p class="dynamic-min-bet">&nbsp;</p>
                    <div id="inputOdds" className="input-num disable">
                        <a id="oddsDown" className="icon-minus"></a>
                        <span id="odds" className="typed">{odds}</span>
                        <a id="oddsUp" className="icon-plus"></a>
                    </div>
                </li>
                <li style={{ flex: 1}}>
                    <p class="dynamic-min-bet" style={{ textAlign: 'center' }} >Min Bet: {parseInt(minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                    <div id="inputStake" onChange={(e)=> {
                        onInputChnageStack(e.target.value);
                        // onChnageStakeValue(defaultStake);
                    }} type="text" value={defaultStake} class="input-num">
                        <a id="stakeDown" class="icon-minus" onClick={()=> {
                            onClickStackMinus();
                            // onChnageStakeValue(defaultStake);
                        }}></a>
                        <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                        <a id="stakeUp" class="icon-plus" onClick={()=>{
                            onClickStackPlus();
                            // onChnageStakeValue(defaultStake);
                        }}></a>
                    </div>
                </li>
            </ul>
            <ul id="stakePopupList" class="coin-list">
                <li><a id="selectStake_1" onClick={() => onInputChnageStack(ssStack?.SSstake1)} stake="10"> {ssStack?.SSstake1}</a></li>
                <li><a id="selectStake_2" onClick={() => onInputChnageStack(ssStack?.SSstake2)} stake="30"> {ssStack?.SSstake2}</a></li>
                <li><a id="selectStake_3" onClick={() => onInputChnageStack(ssStack?.SSstake3)} stake="50"> {ssStack?.SSstake3}</a></li>
                <li><a id="selectStake_4" onClick={() => onInputChnageStack(ssStack?.SSstake4)} stake="200">{ssStack?.SSstake4}</a></li>
                <li><a id="selectStake_5" onClick={() => onInputChnageStack(ssStack?.SSstake5)} stake="500">{ssStack?.SSstake5}</a></li>
                <li><a id="selectStake_6" onClick={() => onInputChnageStack(ssStack?.SSstake6)} stake="1000">{ssStack?.SSstake6}</a></li>
            </ul>

            <div id="keyboard" class="keyboard-wrap">
                <ul id="numPad" class="btn-tel">
                    <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '1') : null} >1</a></li>
                    <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '2') : null} >2</a></li>
                    <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '3') : null} >3</a></li>
                    <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '4') : null} >4</a></li>
                    <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '5') : null} >5</a></li>
                    <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '6') : null} >6</a></li>
                    <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '7') : null} >7</a></li>
                    <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '8') : null} >8</a></li>
                    <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '9') : null} >9</a></li>
                    <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '0') : null} >0</a></li>
                    <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '00') : null} >00</a></li>
                    <li><a  >.</a></li>
                </ul>
                <a id="delete" class="btn-delete" onClick={() => { onInputChnageStack(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
            </div>

            <ul className="btn-list">
                <li>
                    <a id="cancel" onClick={onCancel} className="btn">Cancel
                    </a>
                </li>
                <li>
                    <a id="placeBet" onClick={onPlace} className="btn-send">Place Bet
                    </a>
                </li>
            </ul>
            {renderBottom}
        </div>
    );
};

export default memo(PlaceBetKeybord);
