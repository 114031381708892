import React from 'react'
import electionjpg from '../images/kv/KV085.jpg'
import Matches from './Electionmatches'


export default function Election(props) {
  return (
    <div _ngcontent-lst-c69="" >

      <div _ngcontent-lst-c69="" >
        <div _ngcontent-lst-c69="" class="images-grid">
          <img _ngcontent-lst-c69="" src={electionjpg} alt="" />
        </div>
        <div _ngcontent-lst-c69="" id="gameHighlightWrap" style={{ marginBottom: '0' }} class="highlight-edge-content col3 ">
          <h3 _ngcontent-lst-c69="" style={{ textAlign: 'left' }}>Sports Highlights </h3>
        </div>
        <ul _ngcontent-lst-c69="" class="sports-header">
          <li _ngcontent-lst-c69="" class="col-game" style={{ width: 'calc(64% - 42px)' }}></li>
          <li _ngcontent-lst-c69="" class="sport-odds">1</li>
          <li _ngcontent-lst-c69="" class="col-draw">x</li>
          <li _ngcontent-lst-c69="" class="last-col">2</li>
          <li _ngcontent-lst-c69="" class="info-icon"></li>
        </ul>
        <Matches changeState={props.changeState} />

      </div>
    </div>
  )
}
