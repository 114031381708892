import React,{useState,useEffect} from 'react'


export default function Coundown(props) {
    

    const [timeLeft, setTimeLeft] = useState(props.t);
    var fact = 20;
    if(props.t === 3) fact = 33;
    
    useEffect(() => {
      
      if (timeLeft < 0.02) return;
  
     
      const intervalId = setInterval(() => {
        setTimeLeft(parseFloat(timeLeft - 0.1).toFixed(1));
        let w = 100 - fact*timeLeft;
        if(document.getElementById('progressBar')){
         document.getElementById('progressBar').style.width = w + "%";
        }
        
      }, 100);
      
     
      return () => clearInterval(intervalId); 
      
     }, [timeLeft]);
  
   

   return (
     <tr _ngcontent-lst-c69="" id="fancyBetBar_30042557_170375_1" class="fancy-quick-tr"  >
       <td _ngcontent-lst-c69="" colspan="7">
         <dl _ngcontent-lst-c69="" class="quick_bet-wrap quick_bet-progress">
           <dd _ngcontent-lst-c69="" id="progressBar" class="progress-bar" style={{}}></dd>
           <dd _ngcontent-lst-c69="" class="progress-bar-txt">Placing your bets, Please wait <span id="secRemain"> {timeLeft} sec remaining…</span></dd>
			</dl>
		</td>
	</tr>

   )
}
   

