import React, { useState, useEffect } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import Loading from "../images/sprite/loading40.gif"
import CricketMatches from './CricketMatches';
import SoccerMatches from './SoccerMatches';
import TennisMatches from './TennisMatches';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import axios from 'axios'


// import Soccer from '../images/Banner/slider4.jpeg'
// import IPL from '../images/Banner/ipl.jpeg'
// import IPL2 from '../images/Banner/ipl2023.webp'
// import Basketball from './Basketball';
import BasketballMatches from './BasketballMatches';


const photos = [{
   // name: 'photo 1',
   // url: [IPL],
   // name: 'photo 2',
   // url: [IPL2],
   // name: 'photo 3',
   // url: [Soccer]
}
]

export function Home(props) {


   const [eventType, setEvent] = useState(4);
   //const [nav2, setNav2] = useState();
   //const slider2 = useRef(null);
   // vvvv




   const settings = {
      dots: true,
      slide: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 1500,
      speed: 800,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 1,
      className: 'slidesN'
   }

   return (
      <div>
         <div _ngcontent-njs-c61 class="images-grid  ">
            <Slider  {...settings}>
               {photos.map((photo, id) => {
                  return (
                     <div _ngcontent-njs-c61 key={id}>
                        <img _ngcontent-njs-c61 width="100%" src={photo.url} alt="cric" />
                     </div>
                  )
               })}
            </Slider>

         </div>
         <div _ngcontent-njs-c61 id="gameHighlightWrap" style={{ marginBottom: '0' }} class="highlight-edge-content col3 ">
            <h3 _ngcontent-njs-c61 style={{ textAlign: 'left' }}>Sports Highlights</h3>
            <ul _ngcontent-njs-c61 id="highlightLabel" class="tab-highlight">
               <li _ngcontent-njs-c61 onClick={() => { setEvent(4) }} class=""><a id="highlightTab0" style={{ cursor: ' pointer' }} className={`HLTab ${(eventType === 4) ? "select" : "null"}`}>Cricket</a></li>
               <li _ngcontent-njs-c61 onClick={() => { setEvent(1) }} class=""><a id="highlightTab1" style={{ cursor: ' pointer' }} className={`HLTab ${(eventType === 1) ? "select" : "null"}`}>Soccer</a></li>
               <li _ngcontent-njs-c61 onClick={() => { setEvent(2) }} class=""><a id="highlightTab2" style={{ cursor: ' pointer' }} className={`HLTab ${(eventType === 2) ? "select" : "null"}`} >Tennis</a></li>
            </ul>
         </div>
         <div _ngcontent-njs-c61 class="game-list">


            <div _ngcontent-njs-c61 class="game-list tab-content">

               <ul _ngcontent-njs-c61 class="sports-header">
                  <li _ngcontent-njs-c61 class="col-game" style={{ width: 'calc(64% - 42px)' }}></li>
                  <li _ngcontent-njs-c61 class="sport-odds">1</li>
                  <li _ngcontent-njs-c61 class="col-draw">x</li>
                  <li _ngcontent-njs-c61 class="last-col">2</li>
                  <li _ngcontent-njs-c61 class="info-icon"></li>
               </ul>
               <div _ngcontent-njs-c61 id="tab2">
                  {eventType === 4 && <CricketMatches changeState={props.changeState} />}
                  {eventType === 1 && <SoccerMatches changeState={props.changeState} />}
                  {eventType === 2 && <TennisMatches changeState={props.changeState} />}
                  {eventType === 7522 && <BasketballMatches changeState={props.changeState} />}



               </div>


            </div>

         </div>







      </div>

   )
}

export default React.memo(Home);